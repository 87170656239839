import React, { useState, useEffect } from "react";
import {Box, Grommet} from 'grommet';
import {embedDashboard} from "@superset-ui/embedded-sdk";


const theme = {
  global: {
    colors: {
      brand: '#397aa3',
      'accent-1': 'white',
      'focus': '#ecedf3',
      "my-text-color": "",
      background: '#ecedf3',
    },
    font: {
      family: '',
      size: '18px',
      height: '20px',
      color:"#397aa3",
    },
    select: {
      options:{
        extend:{
          fontWeight:'bold',
        },
      },
    },
  },
  layer: {
    border: {
      radius: 'large',
      intelligentRounding: true,
    },
  },
};


const SupersetComp = (props) => {

    const [dashboardId,setDashboardId]=React.useState("");

  function loginToSuperset() {
  const accessToken = sessionStorage.getItem("accessToken");
  const url = window.location.hostname;
  const parts = url.split('.'); // Dividi la stringa in base al punto
  const domain = "viridia"; // Estrai

  if (!accessToken) {
    console.error("Token di accesso non trovato in sessionStorage.");
    return;
  }

  const apiUrl ="http://viridia.timemate.it/api/superset/login"  // "http://viridia.timemate.it/api/superset/login" //`https://${domain}.timemate.it/api/superset/login` //"http://viridia.timemate.it/api/superset/login" //`https://${domain}.timemate.it/api/superset/login` // window.location.origin+"/api/superset/login";
  const requestBody = {
    "user": sessionStorage.getItem("userId"),
    "tenant": domain,
  };

  // Opzioni per la richiesta HTTP
  const requestOptions = {
    method: "POST",
    headers: {
      "Authorization": `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(requestBody),
  };
  // Effettua la richiesta HTTP
  return fetch(apiUrl, requestOptions)
    .then((response) => {
      if (!response.ok) {
        throw new Error("Errore nella richiesta HTTP.");
      }
      return response.json();
    })
    .then((data) => {
      // Gestisci la risposta qui
      setDashboardId(data.dashboardId);
      return data.token
    })
    .catch((error) => {
      console.error("Errore durante la richiesta:", error);
    });
}




  React.useEffect(()=>{
      try {
         embedDashboard({
            id: dashboardId, // given by the Superset embedding UI
            supersetDomain: "https://superset.timemate.it/",//"http://195.231.65.148:8088/", // "https://superset.timemate.it/", //"http://195.231.65.148:8088/", //ToDo:process.env // http://195.231.65.148:8088/
            mountPoint: document.getElementById("superset-container"), // any html element that can contain an iframe
            fetchGuestToken: () => loginToSuperset(),
            dashboardUiConfig: { // dashboard UI config: hideTitle, hideTab, hideChartControls, filters.visible, filters.expanded (optional)
                hideTitle: true,
                filters: {
                  expanded: true,
                },
            },
          }).catch((e) => console.error("ERRORE ", e))
              .then((val) => {
                document.getElementById("superset-container").children[0].width = "100%";
                document.getElementById("superset-container").children[0].height = "100%";
              })
      } catch (e) {
        console.error("ERRORE", e);
      }
  },[dashboardId])


  return (
    <Grommet theme={theme} themeMode="dark" >
      <Box id={"superset-container"} height="100vh"></Box>
    </Grommet>
  );
}

export default SupersetComp;
