import React, { useState, useEffect } from "react";
import {Box, Grommet, ResponsiveContext, Tabs, Tab, Text, Select, Anchor, DropButton, CheckBox, Button} from 'grommet';
import {gql, useLazyQuery, useMutation} from '@apollo/client';
import {tenant, tenantType } from "../tenant";
import {DataGrid} from "@mui/x-data-grid";
import {Button as ButtonMui} from "@mui/material";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {it} from "date-fns/locale";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import {DateTimePicker} from "@mui/x-date-pickers/DateTimePicker";
import moment from "moment/moment.js";
import {Download, Filter} from "grommet-icons";
import Fab from "@mui/material/Fab";
import Chip from '@mui/material/Chip';
import {useHistory} from "react-router-dom";
import {DELETE_ACTIVITY} from "../activity/activityGQL.js";
import {oreMinutiToString, setSession} from "../../utilis/utils.js";
import LoadingLayer from "../loadingLayer/LoadingLayer.jsx";
import {Switch,FormControlLabel} from "@mui/material";
import {QUERY_GET_ALL_USER_AREA} from "./DashboardGQL.jsx";
import LayerTreeFilter from "./LayerTreeFilter.jsx";
import SupersetComp from "./SupersetComp.jsx";
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import ReactExport from "react-export-excel";
import DownloadIcon from "@mui/icons-material/Download";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const theme = {
  global: {
    colors: {
      brand: '#397aa3',
      'accent-1': 'white',
      'focus': '#ecedf3',
      "my-text-color": "",
      background: '#ecedf3',
    },
    font: {
      family: '',
      size: '18px',
      height: '20px',
      color:"#397aa3",
    },
    select: {
      options:{
        extend:{
          fontWeight:'bold',
        },
      },
    },
  },
  layer: {
    border: {
      radius: 'large',
      intelligentRounding: true,
    },
  },
};

const App = (props) => {

  const [tab,setTab]=React.useState(0);
  const [encodedParams,setEncodedParams]=React.useState(null);
  const [dateFilter,setDateFilter]=React.useState({start: moment().startOf("month"),end:moment().endOf("month")});
  const [activity,setActivity]=React.useState([]);
    /*CHANGE PER IMPLEMENTAZIONE RUOLI*/
  let [filtroDip, setFiltroDip] = React.useState(false); //sessionStorage.getItem('dipendente') !== null ? (sessionStorage.getItem('dipendente') !== '' ? true : false) : false)
  let [filtroMac, setFiltroMac] = React.useState(sessionStorage.getItem('mac') !== null ? (sessionStorage.getItem('mac') !== '' ? true : false) : false);
  let [filtroLav, setFiltroLav] = React.useState(sessionStorage.getItem('lav') !== null ? (sessionStorage.getItem('lav') !== '' ? true : false) : false);
  let [filtroStato, setFiltroStato] = React.useState(sessionStorage.getItem('stato') !== null ? (sessionStorage.getItem('stato') !== '' ? true : false) : false);
  let [filtroArea,setFiltroArea] = React.useState(false);
  const [filtroCust,setFiltroCust] = React.useState(sessionStorage.getItem('cus') !== null ? (sessionStorage.getItem('cus') !== '' ? true : false) : false);
  const [dropdownFiltriDip,setDropdownFiltriDip]=React.useState(null);
  const [dropdownFiltriMac,setDropDownFiltriMac]=React.useState(null);
  const [dropdownFiltroLav,setDropdownFiltroLav]=React.useState(null);
  const [dropdownFiltriStato,setDropDownFiltriStato]=React.useState(null);
  const [dropdownFiltriCust,setDropDownFiltriCust]=React.useState(null);
  const [queryVariables,setQueryVariables]=React.useState({})
  const [totalHour,setTotalHour]=React.useState(0);
  const [checked, setChecked] = useState(false);
  const [showLayerTreeFilter,setShowLayerTreeFilter]=React.useState(false);
  const [selectedNode,setSelectedNode]=React.useState([]);
  const [allUserArea,setAllUserArea]=React.useState([]);
  const history=useHistory();
  const [deleteActivity]=useMutation(DELETE_ACTIVITY);
  let activityApp=[]


  const QUERY_GET_ACTIVITY_TABLE=gql`
  query GetActivity($start: timestamptz, $end: timestamptz,$areeId:[bigint!]) {
    ${tenant}_activity_activity${tenantType}(where: {start: {_gte: $start}, end: {_lte: $end}, area_id:{_in:$areeId} , ${Object.keys(queryVariables).length === 0?"":JSON.stringify(queryVariables).replace("{", "").replaceAll('"',"").slice(0, -1)}}, order_by: {start: asc}) {
      id
      area_id
      approved
      created_by_user
      notes
      operator_notes
      area_area${tenantType} {
        id
        name
        home_coordinates
      }
      start
      end
      notes
      ordinary
      work_duration
      machines_machine${tenantType} {
        id
        name
        chassis_number
        is_machine
      }
      data
      ordinary
      jobs_job${tenantType} {
        id
        name
      }
      users_user${tenantType} {
        id
        name
        surname
        registration_number
      }
    }
  }
`

  React.useEffect(()=>{
    if(checked){
      queryUserArea({variables:{userId:sessionStorage.getItem("userId")}});
    }else{
      setAllUserArea(null);
    }
  },[checked])

  const [queryGetActivityTable,{loading}]= useLazyQuery(QUERY_GET_ACTIVITY_TABLE, {
  fetchPolicy: "no-cache",
    onCompleted: (data) => {
    if(data){
        const res=data[`${tenant}_activity_activity${tenantType}`];
        if(res){
          setActivity(res);
          let totalHour=0;
          res.forEach((activity,index)=>{
              if(activity?.approved==="si" || activity?.approved==="SI" || activity?.approved==="manuale" || activity?.approved==="MANUALE"){
                let difference=moment(activity.end).diff(moment(activity.start), 'seconds');
                if(difference>0){
                  totalHour+=moment(activity.end).diff(moment(activity.start), 'seconds');
                }
              }
          });
          activityApp=[...res];
          setTotalHour(totalHour);
        }
      }
    },
    onError:(error)=>{
      console.log(error);
    },
    notifyOnNetworkStatusChange: true, // did the work
  });

   const [queryUserArea]= useLazyQuery(QUERY_GET_ALL_USER_AREA, {
  fetchPolicy: "no-cache",
    onCompleted: (data) => {
    const userArea=data[`${tenant}_users_userarea`].map((el)=>el.area_id);
    if(userArea){
        setAllUserArea(userArea)
      }
    },
    onError:(error)=>{
      console.log(error);
    },
    notifyOnNetworkStatusChange: true, // did the work
  });

  React.useEffect(()=>{
    const startRange=sessionStorage.getItem("startRange");
    const endRange=sessionStorage.getItem("endRange");
    if(startRange && endRange){
      setDateFilter({start:moment(startRange),end:moment(endRange)})
    }

    const queryVaribales=sessionStorage.getItem("queryVariables");
    if(queryVaribales){
      setQueryVariables(JSON.parse(queryVaribales));
    }
    const lav=sessionStorage.getItem("lav");
    if(lav){
      setDropdownFiltroLav(sessionStorage.getItem("dropdownFiltroLav"))
      setFiltroLav(lav==="true");
    }

    const dip=sessionStorage.getItem("dipendente");
    if(dip){
      setDropdownFiltriDip(sessionStorage.getItem("dropdownFiltriDip"))
      setFiltroDip(dip==="true");
    }

    const mac=sessionStorage.getItem("mac");
    if(mac){
      setDropDownFiltriMac(sessionStorage.getItem("dropdownFiltriMac"))
      setFiltroMac(mac==="true");
    }

    const stato=sessionStorage.getItem("stato");
    if(stato) {
      setDropDownFiltriStato(sessionStorage.getItem("dropdownFiltriStato"))
      setFiltroStato(stato === "true");
    }

    const cus = sessionStorage.getItem("cus");
    if(cus){
      setDropDownFiltriCust(sessionStorage.getItem("dropdownFiltriCus"))
      setFiltroCust(cus==="true");
    }

  },[])

  React.useEffect(()=>{
    if(!checked){
      queryGetActivityTable({variables:{...dateFilter,areeId:props.aree.length>0? props.aree : JSON.parse(sessionStorage.getItem("areeId"))}});
    }else if(checked && allUserArea){
      queryGetActivityTable({variables:{...dateFilter,areeId:allUserArea}});
    }
  },[props.aree,allUserArea])

  React.useEffect(()=>{
    if(filtroArea){
       queryGetActivityTable({variables:{...dateFilter,areeId:selectedNode}});
    }else{
       if(!checked){
        queryGetActivityTable({variables:{...dateFilter,areeId:props.aree.length>0? props.aree : JSON.parse(sessionStorage.getItem("areeId"))}});
      }else if(checked && allUserArea){
        queryGetActivityTable({variables:{...dateFilter,areeId:allUserArea}});
      }
    }
  },[filtroArea,selectedNode])

  React.useEffect(()=>{
    if(activity && dropdownFiltriDip?.id){
      sessionStorage.setItem("dropdownFiltriDip",dropdownFiltriDip.id);
      setQueryVariables({...queryVariables,user_id:{"_eq":dropdownFiltriDip.id}})
    }
    if(dropdownFiltriDip===null){
       if(queryVariables["user_id"]){
          let queryVariablesApp={...queryVariables};
          delete queryVariablesApp.user_id;
          setQueryVariables(queryVariablesApp)
        }
    }

  },[dropdownFiltriDip])

  React.useEffect(()=>{
    if(!filtroDip){
      let queryVariablesApp={...queryVariables};
      if(queryVariablesApp.user_id){
        delete queryVariablesApp.user_id
        setQueryVariables(queryVariablesApp)
      }
    }
  },[filtroDip])

  React.useEffect(()=>{
    if(activity && dropdownFiltriMac?.id){
      sessionStorage.setItem("dropdownFiltriMac",dropdownFiltriMac.id);
      setQueryVariables({...queryVariables,machine_id:{"_eq":dropdownFiltriMac.id}})
    }

     if(dropdownFiltriMac===null){
       if(queryVariables["machine_id"]){
          let queryVariablesApp={...queryVariables};
          delete queryVariablesApp.machine_id;
          setQueryVariables((queryVariablesApp))
        }
    }
  },[dropdownFiltriMac])

  React.useEffect(()=>{
    if(activity && dropdownFiltriCust?.id){
      sessionStorage.setItem("dropdownFiltriCust",dropdownFiltriCust.id);
      setQueryVariables({...queryVariables,machine_id:{"_eq":dropdownFiltriCust.id}})
    }

    if(dropdownFiltriCust===null){
      if(queryVariables["machine_id"]){
        let queryVariablesApp={...queryVariables};
        delete queryVariablesApp.machine_id;
        setQueryVariables((queryVariablesApp))
      }
    }
  },[dropdownFiltriCust])

  React.useEffect(()=>{
    if(!filtroMac){
      let queryVariablesApp={...queryVariables};
      if(queryVariablesApp.machine_id){
        delete queryVariablesApp.machine_id
        setQueryVariables(queryVariablesApp)
      }
    }
  },[filtroMac])

  React.useEffect(()=>{
    if(!filtroCust){
      let queryVariablesApp={...queryVariables};
      if(queryVariablesApp.machine_id){
        delete queryVariablesApp.machine_id
        setQueryVariables(queryVariablesApp)
      }
    }
  },[filtroCust])


  React.useEffect(()=>{
    if(activity && dropdownFiltroLav?.id){
       sessionStorage.setItem("dropdownFiltroLav",dropdownFiltroLav.id);
      setQueryVariables({...queryVariables,job_id:{"_eq":dropdownFiltroLav.id}})
    }

    if(dropdownFiltroLav===null){
       if(queryVariables["job_id"]){
          let queryVariablesApp={...queryVariables};
          delete queryVariablesApp.job_id;
          setQueryVariables((queryVariablesApp))
        }
    }

  },[dropdownFiltroLav])

  React.useEffect(()=>{
    if(!filtroMac){
      let queryVariablesApp={...queryVariables};
      if(queryVariablesApp.job_id){
        delete queryVariablesApp.job_id
        setQueryVariables(queryVariablesApp)
      }
    }
  },[filtroLav])


  React.useEffect(()=>{
    if(activity && dropdownFiltriStato){
       sessionStorage.setItem("dropdownFiltriStato",dropdownFiltriStato);
      setQueryVariables({...queryVariables,approved:{"_eq":dropdownFiltriStato}})
    }
  },[dropdownFiltriStato])

  React.useEffect(()=>{
    if(!filtroStato){
      let queryVariablesApp={...queryVariables};
      if(queryVariablesApp.approved){
        delete queryVariablesApp.approved
        setQueryVariables(queryVariablesApp)
      }
    }

    if(filtroStato===null){
       if(queryVariables["approved"]){
          let queryVariablesApp={...queryVariables};
          delete queryVariablesApp.approved;
          setQueryVariables((queryVariablesApp))
        }
    }

  },[filtroStato])

  React.useEffect(()=>{
    if(queryVariables){
      sessionStorage.setItem("queryVariables",JSON.stringify(queryVariables));
    }
  },[queryVariables])

  React.useEffect(()=>{
    if(dateFilter?.start){
      sessionStorage.setItem("startRange",dateFilter?.start);
    }

    if(dateFilter?.end){
      sessionStorage.setItem("endRange",dateFilter?.end);
    }
  },[dateFilter])

  /**
   * Funzione che salva il dipendente selezionato nel filtro Dipendente
   */
  const handleSelectDipendente = (dipendente) => {
    if(dipendente) {
      sessionStorage.setItem('dipendente', dipendente);
      setFiltroDip(true);
    } else {
      sessionStorage.removeItem('dipendente');
      setFiltroDip(false);
    }
  }

  /**
   * Funzione che salva il macchinario selezionato nel filtro Macchinario
   */
  const handleSelectMacchinari = (mac) => {
    if(mac) {
      sessionStorage.setItem('mac', mac);
      setFiltroMac(true);
    } else {
      sessionStorage.removeItem('mac');
      setFiltroMac(false);
    }
  }

  const handleSelectCustomer = (cus) => {
    if(cus) {
      sessionStorage.setItem('cus', cus);
      setFiltroCust(true);
    } else {
      sessionStorage.removeItem('cus');
      setFiltroCust(false);
    }
  }

  /**
   * Funzione che salva il lavoro selezionato nel filtro Lavoro
   */
  const handleSelectLavori = (lav) => {
    if(lav) {
      sessionStorage.setItem('lav', lav);
      setFiltroLav(true);
    } else {
      sessionStorage.removeItem('lav');
      setFiltroLav(false);
    }
  }

  /**
   * Funzione che salva lo stato selezionato nel filtro Stato
   */
  const handleSelectStato = (stato) => {
    if(stato) {
      sessionStorage.setItem('stato', stato);
      setFiltroStato(true);
    } else {
      sessionStorage.removeItem('stato');
      setFiltroStato(false);
    }
  }

  /*
    Funzione che in base all'utente loggato ottiene l'array di cantieri da passare all'iframe
    per dataStudio
  */
  function getCantieri(){
    const cantieriName=props.cantieri.map((cantiere)=>cantiere.name);
    var params = {
      "ds8.cantiereurl2": cantieriName,
      "ds41.cantiereurldipendenti":cantieriName
    };
    var paramsAsString = JSON.stringify(params);
    var encodedParamsApp = encodeURIComponent(paramsAsString)
    setEncodedParams(encodedParamsApp);
  }

  React.useEffect(()=>{
   // getCantieri();
  },[props.cantieri])

  React.useEffect(()=>{
    if(dateFilter?.start && dateFilter?.end){
       queryGetActivityTable({variables:{...dateFilter,areeId:props.aree.length>0? props.aree : JSON.parse(sessionStorage.getItem("areeId"))}});
    }
  },[dateFilter])


  const columnActivity = [
    { field: 'approved', headerName: 'Stato', width: 170,
     renderCell: (cellValues) => {
        const approved=cellValues.row.approved.toLowerCase();
        return (
           <Chip style={{backgroundColor: approved==="SI" ||  approved==="si" ?"#66bb6a": approved==="MANUALE" ||  approved==="manuale" ?"#0277bd":approved==="CONFERMATA" ||  approved==="confermata" ||  approved==='""'?"#fe7652":approved==="PARTITA" ||  approved==="partita"?"#ffeb3b":approved==="CHIUSURA"||  approved==="chiusura"?"#f06292": approved==""?"#bdbdbd": approved==="ERRATA" || approved==="errata"?"#c80100":""}}
				label={approved==="SI" ||  approved==="si" ?"Terminata":approved==="MANUALE" ||  approved==="manuale"?"Manuale":approved==="CONFERMATA" ||  approved==="confermata" ?"Avviata":approved==="PARTITA" ||  approved==="partita" ?"In pausa":approved === 'CHIUSURA' ||  approved==="chiusura" ?"Chiusura Giornaliera":approved===""?"Pending": approved==="ERRATA" || approved==="errata"?"Non confermata":""}
			/>
          );
      },
    },
    { field: `users_user${tenantType}`, headerName: 'Operatore',width:250,
      renderCell: (cellValues) => {
        return (
          <Text size="small">{cellValues.row[`users_user${tenantType}`]?.name+" "+cellValues.row[`users_user${tenantType}`]?.surname || ""}</Text>
          );
      },
    },
    { field: `id` , headerName :'Macchinario', width: 300,
      renderCell: (cellValues) => {
        if(cellValues.row[`machines_machine${tenantType}`]?.is_machine){
          return (
              <Text size="small">{cellValues.row[`machines_machine${tenantType}`]?.name || ""}</Text>
          );
        }
        return(
            <Text></Text>
        )
      },
    },
    { field: `machines_machine${tenantType}` , headerName :'Cliente', width: 300,
      renderCell: (cellValues) => {
        if(!cellValues.row[`machines_machine${tenantType}`]?.is_machine) {
          return (
              <Text size="small">{cellValues.row[`machines_machine${tenantType}`]?.name || ""}</Text>
          );
        }
        return(
            <Text></Text>
        )
      },
    },
    { field: `jobs_job${tenantType}` , headerName :'Lavoro', width: 200,
      renderCell: (cellValues) => {
          return (
              <Text size="small">{cellValues.row[`machines_machine${tenantType}`]?.name || ""}</Text>
          );
      },
    },
    { field: `area_area${tenantType}` , headerName :'Area', width: 200,
      renderCell: (cellValues) => {
        return (
          <Text size="small">{cellValues.row[`area_area${tenantType}`]?.name || ""}</Text>
          );
      },},
    { field: `data` , headerName :'Data', width: 100 },
    { field: `start` , headerName :'Inizio', width: 100,
      renderCell: (cellValues) => {
        return (
          <Text size="small">{moment(cellValues.row.start).format("HH:mm") || ""}</Text>
          );
      },
    },
    { field: `end` , headerName :'Fine', width: 100,
      renderCell: (cellValues) => {
        return (
           <Text size="small">{moment(cellValues.row.end).format("HH:mm") || ""}</Text>
          );
      },
    },
    {
    field: 'Modifica',
    headerName: 'Modifica',
    width: 300,
    renderCell: (cellValues) => {
      return (
        <Box direction="row" gap="medium">
          <ButtonMui
            variant="contained"
            color="success"
            onClick={(event) => {
               console.log( cellValues.row.id)
              history.push({
                pathname: '/attivitaDettaglio',
                state: {
                  userId:cellValues.row[`users_user${tenantType}`],
                  start:cellValues.row.start,
                  end:cellValues.row.end,
                  activityData:cellValues.row,
                   modify:false,
                }
              })
            }}
          >
            TRACCIA
          </ButtonMui>
            <ButtonMui
            variant="contained"
            color="warning"
            onClick={(event) => {
              history.push({
                pathname: '/attivitaDettaglio',
                state: {
                  userId:cellValues.row[`users_user${tenantType}`],
                  start:cellValues.row.start,
                  end:cellValues.row.end,
                  activityData:cellValues.row,
                  modify:true,
                }
              })
            }}
          >
            Modifica
          </ButtonMui>
          <ButtonMui
            variant="contained"
            color="error"
            onClick={(event) => {
                const res=window.confirm("Sei sicuro di voler eliminare l'attività?");
                if(res){
                     deleteActivity({variables:{id:cellValues.row.id}})
                         .then((value)=>{
                            if(!checked){
                              queryGetActivityTable({variables:{...dateFilter,areeId:props.aree.length>0? props.aree : JSON.parse(sessionStorage.getItem("areeId"))}});
                            }else if(checked && allUserArea){
                               queryGetActivityTable({variables:{...dateFilter,areeId:allUserArea}});
                            }
                         })
                         .catch((error)=>console.error(error))
                }
            }}
          >
            Cancella
          </ButtonMui>
        </Box>
      );},
   }
  ];


  const switchHandler = (event) => {
    setChecked(event.target.checked);
  };


   function downloadCSV(username, password) {
    const url = window.location.hostname!=="localhost"?window.location.href.replace(/\/home$/, '') + "/export/export-csv" : process.env.REACT_APP_BACKEND_HOST+ "/export/export-csv"
    const accessToken = sessionStorage.getItem("accessToken");
    const data = {
      tenant: tenant,
      start: dateFilter?.start,
      end: dateFilter?.end
    };

    console.log(JSON.stringify(data));
    fetch(url, {
        method: 'POST',
        cors:"no-cors",
        headers: {
          "Authorization": `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      })
      .then(response => response.json())
      .then(result => {
        if(result){
          console.log(result);
          if(result?.url){
             window.open(window.location.href.replace(/\/home$/, '')+"/"+result?.url)
          }
        }
      })
      .catch(error => {
        alert("Errore durante l'operazione");
        console.error('Si è verificato un errore:', error);
      });
  }


  //Funzione per gestire i tab
  const onActive = (nextIndex) =>{setTab(nextIndex)}

  return (
    <Grommet theme={theme} themeMode="dark" >
      <ResponsiveContext.Consumer>
        {size => (
        <Box gap="medium" fill >
          {filtroDip && tab!=1 &&
          <Box margin='xxsmall'>
            <Text >Seleziona l'operatore: </Text>
            <Select
              options={activity.length!==0?[...new Set([...activity].sort(function(a, b) { return a[`users_user${tenantType}`]?.name.localeCompare(b[`users_user${tenantType}`]?.name)}).map((el)=>JSON.stringify(el[`users_user${tenantType}`])))].map(JSON.parse):[]}
              value={dropdownFiltriDip}
              onChange={(option ) =>setDropdownFiltriDip(option.value)}
              labelKey={(option)=>option && option.name && option.surname && option.registration_number && `${option.name} ${option.surname} ${option.registration_number}`}
            />
            { dropdownFiltriDip &&
              <Anchor onClick={() => {setDropdownFiltriDip(null);sessionStorage.removeItem("dipFilter");sessionStorage.removeItem("matricolaFilter")}} label="Rimuovi Filtro" />
            }
          </Box>
        }
        {filtroMac && tab!=1 &&
          <Box margin='xxsmall'>
            <Text >Seleziona il macchinario: </Text>
            <Select
              options={activity.length !== 0
                  ? [...new Set(
                      [...activity]
                          .filter(el => el[`machines_machine${tenantType}`]?.is_machine === true)  // Filtro per is_machine false
                          .sort(function(a, b) {
                            return a[`machines_machine${tenantType}`]?.name.localeCompare(b[`machines_machine${tenantType}`]?.name);
                          })
                          .map(el => JSON.stringify(el[`machines_machine${tenantType}`]))
                  )].map(JSON.parse)
                  : []
              }
              value={dropdownFiltriMac}
              onChange={(option ) => setDropDownFiltriMac(option.value)}
              labelKey={(option)=> option && option.name && option.chassis_number && `${option.name} ${option.chassis_number}`}
            />
            { dropdownFiltriMac &&
              <Anchor onClick={() => {setDropDownFiltriMac(null)}} label="Rimuovi Filtro" />
            }
          </Box>
        }
          {filtroCust && tab!=1 &&
              <Box margin='xxsmall'>
                <Text >Seleziona il cliente: </Text>
                <Select
                    options={activity.length !== 0
                        ? [...new Set(
                            [...activity]
                                .filter(el => el[`machines_machine${tenantType}`]?.is_machine === false)  // Filtro per is_machine false
                                .sort(function(a, b) {
                                  return a[`machines_machine${tenantType}`]?.name.localeCompare(b[`machines_machine${tenantType}`]?.name);
                                })
                                .map(el => JSON.stringify(el[`machines_machine${tenantType}`]))
                        )].map(JSON.parse)
                        : []
                    }
                    value={dropdownFiltriCust}
                    onChange={(option ) => setDropDownFiltriCust(option.value)}
                    labelKey={(option)=> option && option.name && option.chassis_number && `${option.name} ${option.chassis_number}`}
                />
                { dropdownFiltriCust &&
                    <Anchor onClick={() => {setDropDownFiltriCust(null)}} label="Rimuovi Filtro" />
                }
              </Box>
          }
         {filtroLav && tab!=1 &&
          <Box margin='xxsmall'>
            <Text >Seleziona il lavoro: </Text>
            <Select
              options={activity.length!==0? [...new Set([...activity].sort(function(a, b) { return a[`jobs_job${tenantType}`]?.name.localeCompare(b[`jobs_job${tenantType}`]?.name)}).map((el)=>JSON.stringify(el[`jobs_job${tenantType}`])))].map(JSON.parse):[]}
              value={dropdownFiltroLav}
              onChange={(option ) => setDropdownFiltroLav(option.value)}
              labelKey={(option)=>option && option.name && `${option.name}`}
            />
            { dropdownFiltroLav &&
              <Anchor onClick={() => {setDropdownFiltroLav(null)}} label="Rimuovi Filtro" />
            }
          </Box>
        }
         {filtroStato && tab!=1 &&
          <Box margin='xxsmall'>
            <Text >Seleziona lo stato: </Text>
            <Select
              options={activity.length!==0? [...new Set([...activity].map((el)=>el.approved))]:[]}
              value={dropdownFiltriStato}
              onChange={(option ) => setDropDownFiltriStato(option.value)}
              labelKey={(option)=>option && `${option}`}
            />
            { dropdownFiltriStato &&
              <Anchor onClick={() => {setDropDownFiltriStato(null)}} label="Rimuovi Filtro" />
            }
          </Box>
        }
           <Box direction="row">
              <Box width="100%"  height="97vh" overflow="auto" style={{overflowY:"hidden"}}>
                <Tabs alignControls="start" onActive={onActive}>
                  <Tab title="Attività">
                      <Box pad="small" gap="small">
                           <Box direction="row" gap="small">
                              <LocalizationProvider locale={it} dateAdapter={AdapterMoment}>
                                <DateTimePicker
                                  ampm={false}
                                  format="DD-MM-YYYY HH:mm"
                                  label="Ora inizio"
                                  value={dateFilter?.start}
                                  onChange={(newValue)=>setDateFilter({...dateFilter,start:moment(newValue)})}
                                  />
                              </LocalizationProvider>
                              <LocalizationProvider locale={it} dateAdapter={AdapterMoment}>
                                  <DateTimePicker
                                    ampm={false}
                                    format="DD-MM-YYYY HH:mm"
                                    label="Ora fine"
                                    value={dateFilter?.end }
                                    onChange={(newValue)=>setDateFilter({...dateFilter,end:moment(newValue)})}
                                  />
                               </LocalizationProvider>
                             {
                               /*
                               *   <Fab style={{marginLeft:"30px"}} size="medium" color="primary" onClick={(e)=>console.log("Dasdas")}>
                                     <DownloadIcon />
                                    </Fab>
                               * **/
                             }
                              <FormControlLabel control={<Switch checked={checked} onChange={switchHandler}/>} label={checked?"Mostra tutte le aree assegnate":"Mostra solo l'area selezionata"} />
                             {
                               props?.exportCsvExternalCode &&
                                <Button label={"Scarica CSV"} onClick={downloadCSV} style={{width:'160px',height:'34px',fontSize:'15px',background:'#397aa3',color:'white',fontWeight:'bold',textTransform: 'uppercase'}}/>

                             }
                           </Box>
                          <Box gap="small">
                            <Text weight={"bold"}>ORE TOTALI NEL RANGE SELEZIONATO: {oreMinutiToString((totalHour/3600))}</Text>
                           <div style={{ height: '75vh', width: '100%' }}>
                            <DataGrid
                              rows={activity}
                              columns={columnActivity}
                            />
                           </div>
                          </Box>
                      </Box>
                  </Tab>
                  {
                    props?.permissionSuperset &&
                       <Tab title="Analitiche">
                        <SupersetComp/>
                      </Tab>
                  }
                  {
                    tab!=1 && <Fab aria-label="like" sx={!filtroArea ? { position: 'absolute', bottom: 68,right: 30}:{ position: 'absolute', bottom: 16,right: 220}} color="white">
                    <DropButton
                      icon={<Filter color='green'/>}
                      style={{
                        fontSize: '28px',
                        background: '#FFFFFF',
                        color: 'white',
                        fontWeight: 'bold',
                        textTransform: 'uppercase',
                        borderRadius: '50%', // Rende il DropButton circolare
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        boxShadow: '0 3px 6px rgba(0, 0, 0, 0.16)',
                        width: 56, // Imposta la larghezza desiderata del pulsante
                        height: 56,
                      }}
                      dropAlign={{ bottom: 'bottom', right: 'left' }}
                      dropContent={
                        <>
                          <Box pad="small">
                            <CheckBox
                              checked={filtroDip}
                              label="Filtro Dipendente"
                              onChange={(event) => handleSelectDipendente(event.target.checked)}
                            />
                          </Box>
                          <Box pad="small">
                            <CheckBox
                              checked={filtroMac}
                              label="Filtro Macchinario"
                              onChange={(event) => handleSelectMacchinari(event.target.checked)}
                            />
                          </Box>
                          <Box pad="small">
                            <CheckBox
                                checked={filtroCust}
                                label="Filtro Cliente"
                                onChange={(event) => handleSelectCustomer(event.target.checked)}
                            />
                          </Box>
                          <Box pad="small">
                            <CheckBox
                              checked={filtroLav}
                              label="Filtro Lavoro"
                              onChange={(event) => handleSelectLavori(event.target.checked)}
                            />
                          </Box>
                          <Box pad="small">
                            <CheckBox
                              checked={filtroStato}
                              label="Filtro Stato"
                              onChange={(event) => handleSelectStato(event.target.checked)}
                            />
                          </Box>
                           <Box pad="small">
                            <CheckBox
                              checked={filtroArea}
                              label="Filtro Area"
                              onChange={(event) => {
                                if(event.target.checked){
                                  setShowLayerTreeFilter(true);
                                }
                                setFiltroArea(event.target.checked)}}
                            />
                          </Box>
                          </>
                        }
                      />
                    </Fab>

                  }

                  {
                    /*<Tab title="Costi">
                    <Box pad="1%" overflow="auto">
                      {encodedParams!==null?
                        <iframe frameBorder="0" style={{width:"100%", height:"800px"}} src={"https://lookerstudio.google.com/embed/reporting/262bb9b8-4b29-4945-910f-029b9b64fbe6/page/ebpnC?params="+encodedParams} ></iframe>
                        :
                        null
                        }
                      </Box>                    
                  </Tab> 
                      */}

                </Tabs>
              </Box>
             {
               showLayerTreeFilter && <LayerTreeFilter onEscLayer={()=>setShowLayerTreeFilter(false)} selectedNodes={selectedNode} setSelectedNodes={setSelectedNode}/>
             }
             {
               filtroArea &&
                  <Fab variant="extended" size="small" color="primary" sx={{ position: 'absolute', bottom: 16,right: 10}} onClick={()=>setShowLayerTreeFilter(true)}>
                    <AccountTreeIcon sx={{ mr: 1 }} />
                    Mostra filtri aree
                  </Fab>
             }
             {
           tab!==1 && activity &&
              <ExcelFile element={
                <Box margin='small' gap="small" alignSelf="end" style={{position:'fixed',bottom:'65px',zIndex:'5',right:'6%'}}>
                  <Box alignSelf="end">
                    <Button
                          icon={<Download color="white"/>}
                          style={{fontSize:'28px',background:'#8FBCA7', padding:'10px',color:'white',fontWeight:'bold',textTransform: 'uppercase'}}>
                    </Button>
                  </Box>
                </Box>
                }>
                <ExcelSheet data={activity} name="Attività">
                    <ExcelColumn label="Nome Operatore"
                                 value={(col) => col.users_usergolf?.name ? col.users_usergolf?.name.toString():"" }/>
                    <ExcelColumn label="Cognome Operatore" value={(col) => col.users_usergolf?.surname ? col.users_usergolf?.surname.toString():"" }/>
                    <ExcelColumn label="Macchinario" value={(col) => col.machines_machinegolf?.name ? col.machines_machinegolf?.name.toString():""}/>
                    <ExcelColumn label="Lavoro" value={(col) => col.jobs_jobgolf?.name ? col.jobs_jobgolf?.name.toString():""}/>
                    <ExcelColumn label="Area" value={(col) => col.area_areagolf?.name ? col.area_areagolf?.name.toString():""}/>
                    <ExcelColumn label="Data" value="data"/>
                    <ExcelColumn label="Inizio" value="start"/>
                    <ExcelColumn label="Fine" value="end"/>
                </ExcelSheet>
            </ExcelFile>
          }
          </Box>
          {
            loading && <LoadingLayer/>
          }

            </Box>
        )}
      </ResponsiveContext.Consumer>
    </Grommet>
  );
}

export default App;
