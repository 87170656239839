import {Layer,Box,Text} from "grommet";
import { CircularProgress } from "@mui/material";

function LoadingLayer(){
    return(
        <Layer>
            <Box align="center" pad="medium" gap="small">
                 <CircularProgress  value={90}/>
            </Box>
        </Layer>
    )
}

export default LoadingLayer;